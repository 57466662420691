import { useRouter } from 'next/router'
import { useState, FC } from 'react'
import { SubmitHandler } from 'react-hook-form'
import { usePathname } from 'next/navigation'

import { Section } from 'components/section'
import { Heading } from 'components/heading'
import { MarketModel } from 'api/nest'
import { StatesMap } from 'components/states-map'
import { StatesMapZipForm } from 'components/states-map-zip-form'
import { SearchMarketsByZipCode } from 'components/search-markets-by-zip-code'
import { IZipFormProps } from 'components/states-map-zip-form/types'
import { DIALOG_NAME } from 'const/dialogs'
import { useControlRouterDialog } from 'components/dialog'
import { useFlowStorage } from 'utils/use-flow-storage'
import { routes } from 'utils/routes'
import { nest } from 'api'
import { PricingCalculationsDialog } from 'components/search-markets-by-zip-code/pricing-calculations-dialog'
import { LinkButton } from 'components/links'
import { checkForLandingPage } from 'components/header/default-header/utils'

import classes from './listOfMarkets.module.css'

interface ListOfMarketsProps {
  markets: MarketModel[]
}

export const ListOfMarkets: FC<ListOfMarketsProps> = ({ markets }) => {
  const [, setFlowStorageData] = useFlowStorage()
  const { push } = useRouter()
  const [zipCode, setZipCode] = useState<string>('')
  const pathname = usePathname()

  const { isLandingPage } = checkForLandingPage(pathname)

  const { open } = useControlRouterDialog()

  const onSubmit: SubmitHandler<IZipFormProps> = async ({ zipCode }) => {
    sessionStorage.removeItem('pricingCalculation')
    setZipCode(zipCode)
    await open(DIALOG_NAME.searchMarketByZipCode)
  }

  const onSelectMarket = async (market: MarketModel) => {
    if (market && zipCode) {
      const postalCode = await nest.postalCodes.findByPostalCode(zipCode)

      setFlowStorageData({
        market: {
          zipCode,
          uuid: market.uuid,
          defaultMaxPayout: market.defaultMaxPayout,
          canEditMaxPayout: market.canEditMaxPayout,
          defaultOfferingUuid: market.defaultOffering?.uuid,
          state: postalCode.state,
        },
      })
    }

    push(routes.stepProperty)
  }

  const openCalculationsModal = async () => {
    await open(DIALOG_NAME.homePagePricingCalculations)
  }

  return (
    <div className={classes.container} id={routes.listOfMarketsAnchor}>
      <Section className={classes.section}>
        <div className={classes.leftSectionWrapper}>
          <Heading theme="h2" className={classes.title}>
            Secure Your Home&apos;s Value Now – Check Availability and Cost Instantly
          </Heading>
          <StatesMapZipForm onSubmit={onSubmit} />
        </div>
        <div>
          <StatesMap markets={markets} linkToMarket={false} hoverEffects={false} />
          {!isLandingPage && (
            <LinkButton
              theme="accentLinkToMobileBtn"
              href={routes.theIndexMarkets}
              className={classes.link}
            >
              Go to markets
            </LinkButton>
          )}
        </div>
      </Section>
      <PricingCalculationsDialog />
      {zipCode && (
        <SearchMarketsByZipCode
          isPriceCalculationsModal={true}
          selectMarketButtonText="Buy now"
          zipCode={zipCode}
          onSelectMarket={onSelectMarket}
          openCalculationsModal={openCalculationsModal}
        />
      )}
    </div>
  )
}
