import { FC } from 'react'

import { Heading } from 'components/heading'
import { MarketName } from 'components/market-name'
import { StrapiImage } from 'api/strapi/StrapiRecord'
import { Image } from 'components/image'
import { Link } from 'components/links'
import { routes } from 'utils/routes'

import { getAdaptiveImageSize } from '../../../../../utils/media'
import classes from './slider-main-item.module.css'

export interface SliderMainItemProps {
  name: string
  position: string
  market: string
  image?: StrapiImage
  id: number
}

const imageSize = getAdaptiveImageSize({
  default: '400px',
  tablet: '60vw',
  mobile: '70vw',
})

export const SliderMainItem: FC<SliderMainItemProps> = ({ id, market, position, name, image }) => {
  return (
    <div className={classes.content}>
      <div className={classes.imageContainer}>
        <Image item={image} fill alt={name} className={classes.image} sizes={imageSize} />
      </div>
      <div className={classes.info}>
        <Heading theme="h2" component="h3" className={classes.name}>
          {name}
        </Heading>
        <Heading theme="h4" component="h4" className={classes.subtitle}>
          {position}
        </Heading>
        <MarketName className={classes.address}>{market}</MarketName>
      </div>
      <Link
        href={routes.whyBuyUseCaseHistory(id)}
        className={classes.globalLink}
        aria-label={`Read more about ${name}, ${position} from ${market}`}
      />
    </div>
  )
}
