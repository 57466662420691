import { FC } from 'react'
import { useSessionStorage, useTimeout } from 'react-use'

import { BuyNowButton } from 'components/buy-now-button'
import { Section } from 'components/section'
import { LinkButton } from 'components/links'
import { routes } from 'utils/routes'

import classes from './ad-market-banner.module.css'
import HomeIcon from './home.svg'
import { Money } from '../money'
import { CloseDialogButton } from '../dialog'
import { withNoSsr } from '../no-ssr'
import { cn } from '../../utils'
import { useMarketByUserOrDefault } from '../../utils/use-full-market-info-by-user'

interface AdMarketBannerProps {}

const _AdMarketBanner: FC<AdMarketBannerProps> = () => {
  const [isClosedBanner, setIsClosedBanner] = useSessionStorage('isClosedMarketBanner', false)
  const { isLoading, market, prices, homepageBannerData } = useMarketByUserOrDefault()

  const [canShow] = useTimeout(10000)

  const close = () => {
    setIsClosedBanner(true)
  }

  if (!canShow || isClosedBanner || isLoading || !market || !prices || !homepageBannerData)
    return null

  const {
    data: { max_payout, monthly_payment, use_custom_values },
  } = homepageBannerData

  const maxPayout = use_custom_values ? Number(max_payout) : market.defaultMaxPayout
  const monthlyPayment = use_custom_values ? Number(monthly_payment) : Number(prices.monthlyPrice)

  return (
    <article className={classes.container}>
      <Section className={classes.wrapper} htmlElement="div">
        <HomeIcon className={classes.icon} />
        <ul className={classes.list}>
          <li className={cn(classes.listItem, classes.mainPriceListItem)}>
            <h6 className={classes.title}>Receive a payout up to</h6>
            <Money value={maxPayout} className={classes.mainPrice} />
            <span className={classes.subtitle}>in the event home prices fall in your market</span>
          </li>
          <li className={classes.listItem}>
            <h6 className={classes.title}>Protection as low as</h6>
            <Money
              value={monthlyPayment}
              className={classes.mainPrice}
              fractionDigitsOptions={{ maximumFractionDigits: 0, minimumFractionDigits: 0 }}
            />{' '}
            <span className={classes.term}>/ month</span>
            <span className={classes.subtitle}>for annual contract</span>
          </li>
        </ul>
        {use_custom_values ? (
          <BuyNowButton toClearStorage={true} toIgnoreSessionRole={true} />
        ) : (
          <LinkButton
            theme="primaryLight"
            className={classes.learnMoreBtn}
            href={routes.howItWorksCalculation}
          >
            Learn more
          </LinkButton>
        )}
        <CloseDialogButton onClose={close} className={classes.close} />
      </Section>
    </article>
  )
}

export const AdMarketBanner = withNoSsr(_AdMarketBanner)
