import { useQuery } from '@tanstack/react-query'

import { nest, strapi } from '../api'
import { QUERY_KEY } from '../const/query'

export const useMarketByUserOrDefault = () => {
  const {
    isLoading: isLoadingMarket,
    error: loadingMarketError,
    data: market,
  } = useQuery([QUERY_KEY.userMarket], () => nest.markets.findByUserOrDefault(), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  const {
    isLoading: isLoadingPrices,
    error: loadingPricesError,
    data: prices,
  } = useQuery(
    [QUERY_KEY.pricesOfMarket, market?.uuid],
    () => {
      if (market && market.defaultOffering) {
        return nest.offerings.calculationPrice(
          market?.defaultOffering?.uuid,
          market?.defaultMaxPayout,
        )
      }
    },
    { refetchOnMount: false, refetchOnWindowFocus: false, enabled: Boolean(market) },
  )

  const {
    isLoading: isHomepageBannerDataLoading,
    error: homepageBannerDataError,
    data: homepageBannerData,
  } = useQuery([QUERY_KEY.homepageBanner], () => strapi.homepageBanner.getBannerData(), {
    refetchOnMount: false,
    refetchOnWindowFocus: false,
  })

  return {
    market,
    prices,
    homepageBannerData,
    isLoading: isLoadingMarket || isLoadingPrices || isHomepageBannerDataLoading,
    error: loadingMarketError || loadingPricesError || homepageBannerDataError,
  }
}
