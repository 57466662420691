import { Dispatch, SetStateAction } from 'react'

import { nest } from 'api'

import { ICalculationsData } from '../utils'

export const getFlowStorageData = async (
  calculationsModalData: ICalculationsData,
  setStorageData: Dispatch<SetStateAction<{}>>,
) => {
  const { pricingData, market, zipCode, protectionAmount } = calculationsModalData
  const { totalPrice, annualPrice } = pricingData

  if (market && zipCode && protectionAmount) {
    const postalCode = await nest.postalCodes.findByPostalCode(zipCode)

    const coverage = {
      maxPayout: protectionAmount,
      annualPrice,
      period: market.defaultOffering?.term,
      totalPrice,
      triggerLevel: market.defaultOffering?.trigger,
    }

    setStorageData({
      coverage: coverage,
      market: {
        zipCode: zipCode,
        uuid: market.uuid,
        defaultMaxPayout: market.defaultMaxPayout,
        canEditMaxPayout: market.canEditMaxPayout,
        defaultOfferingUuid: market.defaultOffering?.uuid,
        state: postalCode.state,
      },
    })
  }
}
