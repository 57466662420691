import { FC } from 'react'

import { Link, LinkButton } from 'components/links'
import { routes } from 'utils/routes'
import { Markdown } from 'components/markdown'

import classes from './slider-description-item.module.css'

export interface SliderDescriptionItemProps {
  id: number
  shortDescription: string
  name: string
  linkName?: string
}

export const SliderShortDescriptionItem: FC<SliderDescriptionItemProps> = ({
  id,
  shortDescription,
  name,
  linkName,
}) => {
  return (
    <>
      <Markdown className={classes.description}>{shortDescription}</Markdown>
      <LinkButton
        href={routes.whyBuyUseCaseHistory(id)}
        theme="accentLinkToMobileBtn"
        className={classes.descriptionLink}
        aria-label={`Read ${linkName || `${name}'s`} story here`}
      >
        Read {linkName || `${name}'s`} story
      </LinkButton>
      <Link
        href={routes.whyBuyUseCaseHistory(id)}
        className={classes.globalLink}
        aria-label={`Read ${linkName || `${name}'s`} story`}
      />
    </>
  )
}
