import { FC, useEffect, useState } from 'react'

import { withRouterDialog } from 'components/dialog'
import { Heading } from 'components/heading'
import { DIALOG_NAME } from 'const/dialogs'
import { MainMarketPropertyList } from 'components/main-order-property-list'
import { Cost } from 'components/cost'
import { BuyNowButton } from 'components/buy-now-button'

import classes from './pricing-calculations-dialog.module.css'
import { getPricingDataFromStorage, ICalculationsData } from '../utils'
import { getFlowStorageData } from './utility-funcs'

const PricingCalculationsContent: FC = () => {
  const [storageData, setStorageData] = useState<{} | null>(null)
  const [calculationsModalData, setCalculationsModalData] = useState<ICalculationsData | null>(null)

  const { pricingData, market, protectionAmount } = calculationsModalData || {}
  const { totalPrice, monthlyPrice, totalPriceWithDiscount, monthlyPriceWithDiscount } =
    pricingData || {}

  useEffect(() => {
    setCalculationsModalData(getPricingDataFromStorage())
  }, [])

  useEffect(() => {
    if (calculationsModalData) {
      getFlowStorageData(calculationsModalData, setStorageData)
    }
  }, [calculationsModalData, setStorageData])

  return calculationsModalData ? (
    <div>
      <p className={classes.title}>Simple pricing</p>
      <div className={classes.list}>
        <MainMarketPropertyList
          marketInfo={{
            trigger: market?.defaultOffering?.trigger,
            term: market?.defaultOffering?.term,
            maxPayout: protectionAmount,
          }}
          includeTerm
          includeMaxPayout
          includeTrigger
          className={classes.listItem}
          classNameDescription={classes.description}
          classNameIcon={classes.icon}
          withEditBtn
          withEditMaxPayout={true}
        />
      </div>
      <Heading theme="h4" className={classes.title}>
        You pay
      </Heading>
      <p>Total amount required for your annual Home Price Protection.</p>
      <Cost
        details={<span>Actual Annual Cost (payable upfront)</span>}
        totalPrice={totalPrice || 0}
        monthlyPrice={monthlyPrice || 0}
        totalPriceWithDiscount={totalPriceWithDiscount || 0}
        monthlyPriceWithDiscount={monthlyPriceWithDiscount || 0}
        className={classes.costWrapper}
      />
      <BuyNowButton toIgnoreSessionRole={true} flowStorageData={storageData ?? undefined} />
    </div>
  ) : null
}

export const PricingCalculationsDialog = withRouterDialog(PricingCalculationsContent, {
  modalName: DIALOG_NAME.homePagePricingCalculations,
  theme: 'onlyContent',
  withoutHeader: true,
})
