import { FC } from 'react'

import { Section } from 'components/section'
import { Heading } from 'components/heading'
import { UseCase } from 'api/strapi/use-cases/types'

import { Slider } from './slider'
import classes from './powerful-protection.module.css'

export interface PowerfulProtectionProps {
  items: UseCase[]
}

export const PowerfulProtection: FC<PowerfulProtectionProps> = ({ items }) => {
  return (
    <>
      <div className={classes.container}>
        <Section className={classes.section}>
          <Heading theme="h2" className={classes.title}>
            Powerful protection tailored to your unique needs
          </Heading>
          <p>
            Whether you’re a first-time homebuyer, an investment property owner, a real estate
            developer, or a multi-homeowner seeking to guard your net worth, Home Price Protection
            can give you the safety and security you require to meet your specific needs.
          </p>
        </Section>
      </div>
      <Slider items={items} />
    </>
  )
}
