import { cn } from 'utils'

import classes from './market-name.module.css'

interface MarketNameProps {
  children: string
  className?: string
}

export const MarketName = ({ children, className }: MarketNameProps) => {
  return <address className={cn(classes.address, className)}>{children}</address>
}
