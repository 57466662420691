import { AdvantagesList } from 'components/advantages'
import { AdvantagesItemProps } from 'components/advantages/AdvantagesItem'
import classes from 'sections/home-page/advantages/advantages.module.css'

import Simple from './images/simple.svg'
import Flexible from './images/flexible.svg'
import PeaceOfMind from './images/peace-of-mind.svg'

const ITEMS: AdvantagesItemProps[] = [
  {
    title: 'It’s simple.',
    content:
      'When home prices decline below a certain threshold in your market, you get paid. You don’t even have to sell your home.',
    icon: Simple,
    iconColor: 'tertiary',
  },
  {
    title: 'It’s flexible.',
    content:
      'You can choose the standard Price Protection option – or customize your protection based on what’s right for you.',
    icon: Flexible,
    iconColor: 'accent',
    classNameIcon: classes.middleIcon,
  },
  {
    title: 'It’s peace of mind.',
    content:
      'Price Protection gives you the confidence to buy and own a property without worrying about the "what ifs".',
    icon: PeaceOfMind,
    iconColor: 'secondary',
  },
]

export const Advantages = () => {
  return (
    <div className={classes.container}>
      <AdvantagesList
        title="It’s protection you can’t get anywhere else."
        subtitle="WHY HOME PRICE PROTECTION?"
        description="There’s no other solution to help you protect your home’s value from potential future market declines."
        items={ITEMS}
        className={classes.mainContainer}
        classNameList={classes.list}
        classNameListItem={classes.listItem}
        classNameIcon={classes.icon}
      />
    </div>
  )
}
