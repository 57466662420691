import { FC } from 'react'

import { usdFormatter } from 'utils'

interface MoneyProps extends Intl.NumberFormatOptions {
  className?: string
  value: number
  fractionDigitsOptions?: {
    maximumFractionDigits: number
    minimumFractionDigits: number
  }
}

export const Money: FC<MoneyProps> = ({
  className,
  value,
  fractionDigitsOptions,
  ...intlNumberOptions
}) => {
  return (
    <span className={className}>
      {usdFormatter(value, intlNumberOptions, fractionDigitsOptions)}
    </span>
  )
}
