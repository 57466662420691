import { useCallback, useEffect } from 'react'
import { useSearchParams, useRouter, ReadonlyURLSearchParams } from 'next/navigation'
import Cookie from 'js-cookie'

export enum UTMParamsEnum {
  utm_source = 'utm_source',
  utm_medium = 'utm_medium',
  utm_campaign = 'utm_campaign',
  utm_term = 'utm_term',
  utm_content = 'utm_content',
}

const utms = [
  UTMParamsEnum.utm_source,
  UTMParamsEnum.utm_medium,
  UTMParamsEnum.utm_campaign,
  UTMParamsEnum.utm_term,
  UTMParamsEnum.utm_content,
]

const expiration_period = 400 // days

export const useUTMQueryParams = (): {
  allUTMCookies: Record<UTMParamsEnum, string | undefined>
  getCookie: (val: UTMParamsEnum) => string | undefined
} => {
  const searchParams = useSearchParams()
  const router = useRouter()

  // url to test http://localhost:3000/?utm_source=0&utm_medium=1&utm_campaign=2&utm_term=3&utm_content=4

  const getCookie = useCallback((val: UTMParamsEnum) => Cookie.get(val), [])

  const setAllUTMCookies = useCallback(
    (sParams: ReadonlyURLSearchParams | null) => {
      if (sParams) {
        const areUTMsAlreadySet = Boolean(getCookie(UTMParamsEnum.utm_source))
        const urlContainsNewUTMs = Boolean(sParams.get(UTMParamsEnum.utm_source))

        if (areUTMsAlreadySet && urlContainsNewUTMs) {
          router.push('/') // clearing url, must not rewrite old utm params in cookies
          return
        }

        // obtaining an arr of utm params keys present in query
        const params = utms.filter((utm) => {
          const val = sParams.get(utm)
          if (val) {
            Cookie.set(utm, val, { expires: expiration_period, sameSite: 'strict' })
            return true
          }
        })

        if (params.length) {
          // hiding utm query params after we set them to cookies
          router.push('/')
        }
      }
    },
    [getCookie, router],
  )

  useEffect(() => {
    setAllUTMCookies(searchParams)
  }, [searchParams, setAllUTMCookies])

  const getAllUTMCookies = useCallback((): Record<UTMParamsEnum, string | undefined> => {
    const cookiesObj: Record<UTMParamsEnum, string | undefined> = {
      [UTMParamsEnum.utm_source]: undefined,
      [UTMParamsEnum.utm_medium]: undefined,
      [UTMParamsEnum.utm_campaign]: undefined,
      [UTMParamsEnum.utm_term]: undefined,
      [UTMParamsEnum.utm_content]: undefined,
    }

    utms.forEach((utm) => {
      const val = getCookie(utm)
      if (val) {
        cookiesObj[utm] = val
      }
    })

    return cookiesObj
  }, [getCookie])

  return {
    allUTMCookies: getAllUTMCookies(),
    getCookie,
  }
}
