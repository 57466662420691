/* eslint-disable import/order */
/* eslint-disable import/no-unresolved */
import { Swiper, SwiperProps, SwiperSlide } from 'swiper/react'
import { Controller, Keyboard, Navigation } from 'swiper'
import { ComponentProps, memo, useState, FC } from 'react'
import { Swiper as SwiperClass } from 'swiper/types'

import { Section } from 'components/section'
import { cn } from 'utils/cn'
import { UseCase } from 'api/strapi/use-cases/types'

import { SliderMainItem, SliderMainItemProps } from './slider-main-item/SliderMainItem'
import {
  SliderDescriptionItemProps,
  SliderShortDescriptionItem,
} from './slider-description-item/SliderShortDescriptionItem'

import 'swiper/css'
import 'swiper/css/navigation'
import classes from './slider.module.css'
import { withNoSsr } from 'components/no-ssr'

const BASE_SWIPER_CONFIG: SwiperProps = {
  slidesPerView: 1,
  slidesPerGroup: 1,
  spaceBetween: 1,
  loop: true,
  breakpoints: {
    1000: {
      allowTouchMove: false,
      slidesPerGroup: 2,
      slidesPerView: 2,
    },
  },
  a11y: {
    prevSlideMessage: 'Vorheriger Slide.',
    nextSlideMessage: 'Nächster Slide.',
  },
}

export type SliderItem = Omit<SliderMainItemProps, 'withLeftSeparator'> & SliderDescriptionItemProps

interface SliderProps {
  items: UseCase[]
}

export const _Slider: FC<SliderProps> = ({ items }) => {
  const [firstSwiper, setFirstSwiper] = useState<SwiperClass | undefined>(undefined)
  const [secondSwiper, setSecondSwiper] = useState<SwiperClass | undefined>(undefined)

  return (
    <article>
      <div className={classes.mainSwiperContainer}>
        <Section className={cn(classes.section, classes.mainSwiperSection)}>
          <Swiper
            {...BASE_SWIPER_CONFIG}
            modules={[Navigation, Controller, Keyboard]}
            navigation={{
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev',
            }}
            keyboard={{ enabled: true }}
            onSwiper={setFirstSwiper}
            controller={{
              control: secondSwiper,
            }}
          >
            {items.map(
              ({
                id,
                history_user_name,
                history_market,
                slider_user_photo,
                history_user_position,
              }) => (
                <SwiperSlide key={id}>
                  <SliderMainItem
                    id={id}
                    market={history_market}
                    name={history_user_name}
                    position={history_user_position}
                    image={slider_user_photo}
                  />
                </SwiperSlide>
              ),
            )}
            <div className="swiper-button-prev" aria-label="Previous slide" />
            <div className="swiper-button-next" aria-label="Next slide" />
          </Swiper>
        </Section>
      </div>

      <Section className={classes.section}>
        <Swiper
          {...BASE_SWIPER_CONFIG}
          modules={[Controller]}
          onSwiper={setSecondSwiper}
          controller={{
            control: firstSwiper,
          }}
        >
          {items.map(({ id, history_user_name, slider_description, slider_link_name }) => (
            <SwiperSlide className={classes.descriptionSliderItem} key={id}>
              <SliderShortDescriptionItem
                linkName={slider_link_name}
                name={history_user_name}
                shortDescription={slider_description}
                id={id}
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </Section>
    </article>
  )
}

_Slider.displayName = 'Slider'
export const Slider = memo<ComponentProps<typeof _Slider>>(withNoSsr(_Slider))
