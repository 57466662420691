import type { GetStaticProps, NextPage } from 'next'
import { useEffect, useRef } from 'react'

import { routes } from 'utils/routes'
import { ListOfMarkets } from 'sections/home-page/list-of-markets'
import { Faq, HomePage, nest, strapi } from 'api'
import { LinkButton } from 'components/links'
import { FaqSection } from 'components/faqs'
import { CTASection } from 'sections/common/cta-section/cta-section'
import { ArticlesSection, WelcomeSection } from 'sections/common'
import { Advantages } from 'sections/home-page/advantages'
import { WhatIs } from 'sections/home-page/what-is'
import { PowerfulProtection } from 'sections/home-page/powerful-protection'
import { UseCase } from 'api/strapi/use-cases/types'
import { REVALIDATION_TIMEOUT } from 'const/config'
import { MarketModel } from 'api/nest'
import { TrustPilot, activateTrustPilot, pageEnum } from 'components/trust-pilot'
import { BuyNowButton } from 'components/buy-now-button'
import { useUTMQueryParams } from 'utils/use-utm-query-params'

import { AdMarketBanner } from '../components/ad-market-banner'
import { SearchMarketsByZipCodePreloadLoadingAnimation } from '../components/search-markets-by-zip-code'
import styles from './index.module.css'
import welcomeImage from './welcom_image.png'

interface Props {
  faqs: Faq[]
  welcome_content: HomePage['welcome_content']
  articles: HomePage['articles']
  useCases: UseCase[]
  markets: MarketModel[]
}

export const getStaticProps: GetStaticProps<Props> = async () => {
  try {
    const [pageData, useCases, markets] = await Promise.all([
      strapi.pages.homePage(),
      strapi.useCases.find({
        populate: ['slider_user_photo'],
      }),
      nest.markets.find(),
    ])

    return {
      revalidate: REVALIDATION_TIMEOUT,
      props: {
        ...pageData,
        useCases: useCases.items,
        markets: markets,
      },
    }
  } catch (e) {
    throw e
  }
}

const Home: NextPage<Props> = ({ faqs, articles, welcome_content, useCases, markets }) => {
  const trustPilotRef = useRef(null)
  useUTMQueryParams()

  useEffect(() => {
    activateTrustPilot(trustPilotRef)
  }, [])

  return (
    <>
      <TrustPilot trustPilotRef={trustPilotRef} page={pageEnum.home} />
      <SearchMarketsByZipCodePreloadLoadingAnimation />
      <WelcomeSection
        {...welcome_content}
        image={welcomeImage.src}
        classNameImage={styles.welcomeImage}
        withBgSun={false}
      >
        <div className={styles.footer}>
          <BuyNowButton toIgnoreSessionRole={true} className={styles.primaryButton} />
          <LinkButton href={routes.howItWorks} theme="secondary">
            How It Works
          </LinkButton>
        </div>
      </WelcomeSection>

      <WhatIs />
      <ListOfMarkets markets={markets} />
      <Advantages />
      <PowerfulProtection items={useCases} />
      {articles && <ArticlesSection articles={articles.items} title={articles.title} />}
      <FaqSection title="Common questions about Price Protection" items={faqs} />
      <CTASection />
      <AdMarketBanner />
    </>
  )
}
export default Home
