import { Section } from 'components/section'
import { Heading } from 'components/heading'
import { Markdown } from 'components/markdown'
import { LinkButton } from 'components/links'
import { routes } from 'utils/routes'
import { Video } from 'components/video'
import { DIALOG_NAME } from 'const/dialogs'

import classes from './what-is.module.css'
import { getAdaptiveImageSize } from '../../../utils/media'

const MAIN_TEXT = `
It is a safety net for your home’s value. We track housing prices in your market. If prices fall below an agreed threshold, you get a payout. It is automatic and hassle free. 

Consider it your shield against market uncertainties.
`

export const WhatIs = () => {
  return (
    <Section className={classes.section}>
      <div>
        <Heading theme="h2" className={classes.title}>
          What is Home Price Protection?
        </Heading>
        <Markdown>{MAIN_TEXT}</Markdown>
        <LinkButton theme="accentLinkToMobileBtn" href={routes.howItWorks} className={classes.link}>
          See how it works
        </LinkButton>
      </div>
      <div>
        <Video
          dialogName={DIALOG_NAME.homePageHowItWorksVideo}
          wistiaId="iigb2vlwc8"
          wistiaSubDomain="rezitrade"
          placeholderImageOptions={{
            sizes: getAdaptiveImageSize({ default: '600px', tablet: '100vw' }),
            alt: 'What is Home Price Protection?',
          }}
          videoShareTitle="What is Home Price Protection?"
        />
      </div>
    </Section>
  )
}
