import { FC, MutableRefObject, useEffect } from 'react'

import classes from './trust-pilot.module.css'

export enum pageEnum {
  home = 'home',
  whyBuy = 'whyBuy',
  clientsLandingPage = 'clientsLandingPage',
}

interface ITrustPilot {
  trustPilotRef: MutableRefObject<null>
  page: pageEnum
}

declare global {
  interface Window {
    Trustpilot: any
  }
}

const cut_ui_template_id = '5406e65db0d04a09e042d5fc'
const cut_ui_height = '30px'
const extended_ui_template_id = '53aa8912dec7e10d38f59f36'
const extended_ui_height = '140px'

export const activateTrustPilot = (ref: MutableRefObject<null>) => {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js'
  script.async = true
  document.head.appendChild(script)

  script.onload = () => {
    const trustbox = ref.current
    if (trustbox && window.Trustpilot) {
      window.Trustpilot.loadFromElement(trustbox)
    }
  }
}

export const TrustPilot: FC<ITrustPilot> = ({ trustPilotRef, page }) => {
  useEffect(() => {
    setTimeout(() => {
      const trustPilotElement = trustPilotRef.current as unknown as HTMLDivElement
      if (trustPilotElement) {
        trustPilotElement.style.visibility = 'visible'
        trustPilotElement.style.opacity = '1'
      }
    }, 500)
  }, [trustPilotRef])

  const getHeight = (page: pageEnum) => {
    switch (page) {
      case pageEnum.home:
        return cut_ui_height
      case pageEnum.whyBuy:
        return extended_ui_height
      case pageEnum.clientsLandingPage:
        return extended_ui_height

      default:
        return extended_ui_height
    }
  }

  const getDataTemplateId = (page: pageEnum) => {
    switch (page) {
      case pageEnum.home:
        return cut_ui_template_id
      case pageEnum.whyBuy:
        return extended_ui_template_id
      case pageEnum.clientsLandingPage:
        return extended_ui_template_id

      default:
        return extended_ui_template_id
    }
  }

  return (
    <div
      ref={trustPilotRef}
      className={classes.trustpilotWidget + ' trustpilot-widget'}
      data-locale="en-US"
      data-template-id={getDataTemplateId(page)}
      data-businessunit-id="650108503c594b42929ff30a"
      data-style-height={getHeight(page)}
      data-style-width="100%"
      data-theme="light"
      data-font-family="Roboto"
      data-text-color="#56554D"
    >
      <a
        href="https://www.trustpilot.com/review/rezitrade.com"
        target="_blank"
        rel="noopener noreferrer"
      >
        Trustpilot
      </a>
    </div>
  )
}
